import { createRoot } from 'react-dom/client';
import { EventType } from '@azure/msal-browser';
import store from 'core/store';
import { updateJustLoggedIn } from 'core/store/auth';
import msalPublicClientApplication from 'shared/utils/msal.utils';
import { saveTokenToLocalStorage } from 'shared/utils/token.utils';
import App from './screens/app';
import reportWebVitals from './reportWebVitals';

import './index.scss';

msalPublicClientApplication.initialize().then(() => {
  msalPublicClientApplication.addEventCallback(event => {
    if (event.eventType === EventType.LOGIN_SUCCESS) {
      // eslint-disable-next-line no-console
      console.warn('login success triggered');
      store.dispatch(updateJustLoggedIn(true));
    }
  });

  msalPublicClientApplication
    .handleRedirectPromise()
    .then(res => {
      if (res) saveTokenToLocalStorage(res.accessToken);
    })
    .finally(() => {
      const container = document.getElementById('root');
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const root = createRoot(container!);

      root.render(<App />);
    });
});

reportWebVitals();
