import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Path } from 'core/constants/path.constants';
import { ESuggestedProductViewId } from 'core/model/enums/suggested-products.enum';
import { getCustomizationElementsAction } from 'core/store/customization';
import { getHighlightedProducts } from 'core/store/highlighted-products';
import { useAppDispatch } from 'core/store/store.utils';
import { getSuggestedProducts } from 'core/store/suggested-products';

const useInitializeHome = (customerId?: number) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const mounted = useRef(false);
  const previousCustomerId = useRef(customerId);

  useEffect(() => {
    const isHomePath = pathname === Path.HOME;
    const hasCustomerChanged = previousCustomerId.current !== customerId;

    if (customerId && isHomePath) {
      if (!mounted.current || hasCustomerChanged) {
        dispatch(getCustomizationElementsAction());
        mounted.current = true;
        previousCustomerId.current = customerId;
      }
      dispatch(getSuggestedProducts(ESuggestedProductViewId.fixedBanner));
      dispatch(getSuggestedProducts(ESuggestedProductViewId.homepage));
      dispatch(getHighlightedProducts());
    }
  }, [customerId, dispatch, pathname]);
};

export default useInitializeHome;
