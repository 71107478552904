import { useEffect } from 'react';
import { getCartAction } from 'core/store/cart';
import { checkoutCustomerSelector } from 'core/store/checkout/selectors';
import { getNavigationTreeAction } from 'core/store/navigation/async-thunks';
import { isIdleCategoriesSelector } from 'core/store/navigation/selectors';
import { useAppDispatch, useAppSelector } from 'core/store/store.utils';
import useInitializeHome from 'screens/home/hooks/use-initialize-home.hooks';

const useInitializeApp = () => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(checkoutCustomerSelector);
  const isIdleCategories = useAppSelector(isIdleCategoriesSelector);

  useInitializeHome(customerId);

  useEffect(() => {
    const initializeReduxStore = () => {
      if (customerId) {
        dispatch(getCartAction());
      }
    };
    initializeReduxStore();
  }, [dispatch, customerId]);

  useEffect(() => {
    const initializeNavigation = () => {
      if (isIdleCategories && customerId) {
        dispatch(getNavigationTreeAction());
      }
    };
    initializeNavigation();
  }, [dispatch, isIdleCategories, customerId]);
};

export default useInitializeApp;
